export default {
  intro: {
    title: "The 2020s have arrived: Happy new decade!",
    text:
      "<p>In celebration of the arrival of the 2020s, an in-depth look at what the next chapter of The Story of Digital might look like.</p><p>Happy new year to you all! The 2020s have arrived, meaning the third decade of the mainstream internet is here. In celebration of 20 years of highs, lows and everything in between, we are seizing the opportunity to look back over the last two decades at how the internet and digitalisation have changed the world of business. We’ll also be looking ahead at the biggest changes the next 10 years might have in store.</p><p>Here at Jungle Minds, we have a long tradition of predicting next year’s trends for tech users, as well as in design, technology and business strategy. That said, we’re the first to admit that not all our predictions are entirely accurate, because, to be perfectly honest, the very idea of predicting the trends that may or may not shape the coming year is really somewhat naïve, while attempting to foresee how a full decade might play out is downright ridiculous. But it’s also a fun way to kick off the new decade :)</p><p>So enjoy! And may the 2020s bring you all the very best in health, love and business.</p>",
    download: "Download the PDF",
  },
  highlights: [
    {
      pattern: "pattern1", // ? Options: "pattern1", "pattern2" & "pattern3"
      backgroundColor: "black", // ? Options: "black", "red" & "white"
      previousBackgroundColor: "white", // ? Options: "black", "red" & "white"
      footer: false, // ? Only last one has a footer
      title: "The story begins",
      text:
        "<p>The origins of the Internet date back to the 1960s, but our story starts in 2000. The dot.com bubble has burst, Google has celebrated its first birthday and - almost as important - Jungle Minds was founded. In short: ‘the mainstream internet’ starts here.</p><p>20 years later, and using the internet is part of almost everything we do. We have grown so familiar with it that it’s easy to forget its remarkable growth. In those twenty years almost the entire global population has access to the internet, and has started to use it across most aspects of everyday life: how we interact with each other, how we buy and sell products, and and how we browse, acquire and use services.</p>",
      quote:
        "2000: 17 million websites, 360 million web users. AOL is still the world's most visited website",
      smallImage: {
        src: "../../images/Small-Graphs-4-sec.gif",
        ratio: 100,
      },
      largeImage: {
        src: "../../images/Large-Graphs-3sec.gif",
        ratio: 40,
      },
    },
    {
      pattern: "pattern2", // ? Options: "pattern1", "pattern2" & "pattern3"
      backgroundColor: "white", // ? Options: "black", "red" & "white"
      previousBackgroundColor: "black", // ? Options: "black", "red" & "white"
      footer: false, // ? Only last one has a footer
      title: "Changing the world of business",
      text:
        "<p>Of course the Internet created new companies and household brands that we now consider to be part of everyday life. But only a few years ago, those same brand names - and the business models they run on - simply didn’t exist.</p>",
      smallImage: {
        src: "../../images/smallTimeline.png",
        ratio: 215,
      },
      largeImage: {
        src: "../../images/largeTimeline.png",
        ratio: 40,
      },
      secondText:
        "<p>First up, search engines structured the internet and made it possible for users to find whatever they were looking for. This, combined with the growth of social media, created new ways to locate and target potential online customers.</p><p>Starting in the early 2000s, newly formed e-commerce companies started building businesses on top of these new opportunities. E-commerce started out with relatively simple ‘low-touch’ goods like books and airline tickets, but this emerging market rapidly grew comfortable about buying more complex products online. And the fact that e-commerce revenues continue to grow at pace is now encouraging e-com players to branch out into more complex markets like fresh groceries and B2B products.</p>",
      secondSmallImage: {
        src: "../../images/smallModels.png",
        ratio: 195,
      },
      secondLargeImage: {
        src: "../../images/largeModels.png",
        ratio: 40,
      },
      thirdQuote:
        "The pace at which consumers welcomed the internet into their lives only truly surged with the massive wave of smartphone adoption in the early 2010s.",
      thirdText:
        "<p>The pace at which consumers welcomed the internet into their lives only truly surged with the adoption of smartphones. These devices emboldened consumers to use the internet for all sorts of daily tasks, including shopping, personal finance, leisure/entertainment, and every imaginable form of communication. It was a mass adoption that spawned entirely new business models and disrupted whole industries. In just 10 years, companies like Netflix and Spotify changed the basis of the media industry from ‘buy’ to ‘subscribe’, while platform-based companies like Airbnb and Uber disrupted entire sectors, as well as challenging the notion of what a company and a workforce is - for better or for worse.</p>",
    },
    {
      pattern: "pattern3", // ? Options: "pattern1", "pattern2" & "pattern3"
      backgroundColor: "black", // ? Options: "black", "red" & "white"
      previousBackgroundColor: "white", // ? Options: "black", "red" & "white"
      footer: false, // ? Only last one has a footer
      title: "The technology that made it possible",
      text:
        "<p>Looking back at the past two decades, we see a clear relationship between the newly available foundational technologies and the business models they enabled. Looking at the 2000s, it’s clear that the rise of e-commerce is directly linked to search engine technology and the unprecedented reach of social media. A look at the rising stars of the 2010s including Uber, Spotify and Netflix, reveals businesses that simply could not exist without the smartphone and the power of large scale cloud computing.</p>",
      quote:
        "The 2000s saw the rise of businesses built on the foundation of search engines and social media. The 2010s saw the birth of brands built upon the possibilities of mobiles and cloud computing.",
      smallImage: {
        src: "../../images/smallTech.png",
        ratio: 165,
      },
      largeImage: {
        src: "../../images/largeTech.png",
        ratio: 40,
      },
      secondText:
        "<p>As is so often the case, these building-blocks arrived well before they proved fit for purpose or able to underpin new business innovation. For example, search engine Altavista was founded in 1995, while early social media platform SixDegrees.com started out in 1997. And the same thing goes for the second decade where foundational layers arrived well before the players that really disrupted and changed business. The iPhone, for example, has been around three years longer than Uber. Amazon Web Services launched four years before Netflix started streaming content.</p>",
      secondQuote:
        "And so the big question is: What kind of new foundational technologies have proved themselves able to power the digital innovation that will shape the new decade?",
    },
    {
      pattern: "pattern1", // ? Options: "pattern1", "pattern2" & "pattern3"
      backgroundColor: "white", // ? Options: "black", "red" & "white"
      previousBackgroundColor: "black", // ? Options: "black", "red" & "white"
      footer: true, // ? Only last one has a footer
      title: "What’s coming in the 2020s?",
      text:
        "<p>As profound as the changes of the last two decades may have seemed, the reality is that only a few industries have been truly, fundamentally changed through digitalisation. In fact, in most industries digital has merely scratched the surface. You would be amazed at how many complex value chains still predominantly run on Excel sheets and poor data.</p><p>But over the last few years, new foundational tools and technologies like Machine Learning, IoT and Distributed Computing have proved their potential impact and business viability. They are no longer just stuff that investors, consultants and other prophets talk about. They work, they are easily available, and, most importantly, they are not even close to reaching the limits of their potential. Now, the third decade of the story is about to unfold. And it will be powered by these technologies and their ability to fundamentally change how complex industries and value chains work.</p>",
      quote:
        "New foundational technologies with the ability to solve more complex business problems",
      smallImage: {
        src: "../../images/smallForward.gif",
        ratio: 230,
      },
      largeImage: {
        src: "../../images/largeForward.gif",
        ratio: 40,
      },
      secondText:
        "<p>And thanks to the previous two decades, almost the entire population of the planet will be part of this new chapter, making the economic potential of digitising industries simply huge. Combine this with the power of new foundational tools that address more complicated business challenges in the more complex industries, and we can only draw one simple conclusion:</p><p>We ain't seen nothing yet.</p><p>So let’s get into prediction mode. Enjoy!</p>",
    },
  ],
  industriesTitle:
    "Seven industries that are going to see massive changes in the next decade",
  industries: [
    {
      title: "Construction",
      quote:
        "The drive for sustainability and the need to use limited space more efficiently will fuel further digitisation of both buildings and how the industry designs and builds them.",
      text:
        "<p>If there’s one sector that we believe has genuinely seen nothin’ yet, it’s the construction industry. Why? Because it’s huge - global output is projected to grow to <a href='https://www.pwc.com/vn/en/industries/engineering-and-construction/pwc-global-construction-2030.html' target='_blank'>$15.5 trillion by 2030</a> - but is still relatively untouched by digitisation. On top of that, it has to adapt to urgent new needs regarding sustainability, personalisation and efficiency. But perhaps most importantly, the industry has plenty of room for improvement. For example: 80% of large construction projects finish late AND over budget. This is mainly due to complexity, long interdependent value chains and a process that has almost zero flexibility, and is therefore unable to respond to changing conditions.</p><p>Digital innovation that has already taken place in other sectors has barely happened in the construction industry. This could take the form of, for example, vertical integration, where contractors move beyond delivering bare-bones houses and start delivering and selling fully decorated homes, or digital collaboration platforms that reduce the need for rigid central coordination by project managers. The latter idea would also allow for more flexibility between the long chain of suppliers involved in financing and developing large projects, allowing builders and future users to collaborate on customisation and personalisation.</p><p>The drive for sustainability and the need to use limited space more efficiently will fuel further digitisation in buildings, and how the industry designs and builds them. That’s why we believe that the construction industry will see massive and fundamental changes through digitisation in the coming decade.</p>",
      image: "../../images/construction.png",
    },
    {
      title: "Manufacturing",
      quote:
        "Described as a ‘trillion dollar opportunity’, the manufacturing industry is another sector that is yet to fully leverage the benefits of digitisation.",
      text:
        "<p>Described by <a href='https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/the-trillion-dollar-opportunity-for-the-industrial-sector' target='_blank'>McKinsey</a> as a ‘trillion dollar opportunity’, the manufacturing industry is another sector that is yet to fully leverage the benefits of digitisation. With profits and growth flatlining in recent years, manufacturers are striving to innovate faster, get closer to customers, and use emerging technologies to create new business models offering more effective ways to grow both their business and their margins. But how?</p><p>Over the last few years we have seen ever more consumer goods manufacturers experimenting with bypassing retailers through direct-to-consumer propositions, often based on subscription services. Although still in the early stages, the benefits of getting closer to end-users will inspire more enthusiasm for exploring this approach. Driven by the possibilities that both connected products and AI offer, it is very likely that manufacturers will have ever better options to develop more personal, ‘performance based’ services to end users.</p><p>Alongside creating new revenue channels for manufacturers, digitisation offers substantial opportunities for rethinking the manufacturing process itself. Digitisation will make collaboration between manufacturers, partners and clients far more efficient, rapid and effective. Another advantage is the ability to better predict market demand through the intelligent maintenance of production capacity, offering very real possibilities of improved profit margins. But most importantly, 3D printing will enable a decentralised approach to manufacturing that will drive more effective ways to produce and collaborate. And it will be well designed digital tools that support this new way of working and the benefits offered by these opportunities.</p>",
      image: "../../images/manufacturing.png",
    },
    {
      title: "Sports",
      quote:
        "The Sports-industry is not only very big and growing rapidly, it is also becoming a more globalised industry.",
      text:
        "<p>With a considerable percentage of the world population reaching levels of prosperity that allow them to watch and actively enjoy both professional and leisure sports, the sports industry is seeing - and will continue to see - massive growth in business potential. With an estimated value of approx <a href='https://www.researchandmarkets.com/reports/4770417/sports-global-market-opportunities-and-strategies?utm_source=BW&utm_medium=PressRelease&utm_code=ctvc8g&utm_campaign=1244426+-+Sports+-+%2524614+Billion+Global+Market+Opportunities+%2526+Strategies+to+2022&utm_exec=joca220prd' target='_blank'>$500 billion in 2018</a>, the industry is big, and is also becoming more globalised.</p><p>Social media has given sporting brand names and individual celebrities incredibly powerful platforms, allowing them to reach fans around the world and build the value of their personal brand. Yet to this day, the economic side of the sports industry still relies heavily on traditional revenue streams including advertising, ticket sales, and merchandising.</p><p>We expect to see efforts by professional sports brands to capitalise on their massive reach and cultural relevance, using digitisation to uncover new revenue models. For example, by adding new services to existing offers, or selling access to specific content directly to fans, rather than through third parties relying on ad-based models. Also in leisure sports, the growth of the market (mainly in emerging territories) will inspire new models, allowing users more flexible access to leisure sports that combine digital content and lifestyle based propositions with physical exercise.</p>",
      image: "../../images/sports.png",
    },
    {
      title: "Automotive & mobility",
      quote:
        "The ever-growing global population, urbanisation and increasing environmental concerns make the need for smarter mobility solutions even more urgent.",
      text:
        "<p>Powered by the shift to EV’s and the increasingly ubiquitous ‘use over own’ proposition, the automotive space has already seen quite some action in the last decade. But we believe the real digital transformation of this industry is yet to take place. Because the thing that we consider as ‘a car’ is rapidly evolving from a physical, standalone device that you drive from A to B, into a connected, intelligent software driven mobility unit. And with that, the way we use and access cars will change.</p><p>In short, the Internet of Cars is a dynamic network made up of intelligent vehicles that share information with devices both inside and outside the car. This will enable them to constantly interact with their surroundings, making autonomous driving safe, and allowing cars to take on entirely new tasks independently. So how will this affect the economic system around the car? First of all, cars will become interfaces through which we access a range of services, generating countless possibilities for new revenue models around entertainment, logistics and ancillary services. Secondly, smart cars avoid accidents and maintain themselves proactively. This will change the current system built on servicing and repairing cars.</p><p>Considering the ever-growing global population, the trend of urbanisation and increasing environmental concerns, the need for smarter mobility solutions that help to make cities more sustainable and liveable, is only going to become more urgent. And smart cars will play a big role in that, making the potential economic impact of the internet of cars enormous. No wonder then, that OEMs, software companies and other suppliers are already positioning themselves in this emerging space.</p>",
      image: "../../images/mobility.png",
    },
    {
      title: "Finance",
      quote:
        "Forces like new open banking regulations (such as PSD2) and the pressure brought by low interest rates will drive the need to deliver new financial services that go beyond traditional banking.",
      text:
        "<p>Although traditionally a conservative sector, the finance industry is no stranger to digitisation. Mobile banking has fundamentally changed our relationship with banks, plus in the last five years, we have seen plenty of serious challengers in the FinTech space. Meanwhile, the bigger 'incumbent’ banks have most definitely not been standing idly by. Being an information business at heart, the finance industry has been a dynamic sector for years, spawning new and substantial ‘digital first’ players like Adyen, Stripe and Oscar.</p><p>So why name the Finance industry as one of the sectors due for big and fundamental changes? Because we believe that the digitisation seen so far predominantly took place in consumer facing financial services. And that’s because the more complex, large scale, mostly B2B financial services that make up the majority of the sector still rely heavily on analogue processes and legacy systems. The size of this opportunity is huge, with the global financial services industry estimated to reach <a href='https://www.thebusinessresearchcompany.com/report/financial-services-global-market-report' target='_blank'>$26500+ billion USD by 2022</a>. Combine that opportunity with external forces like new open banking regulations (such as PSD2), plus the pressure brought by low interest rates, and it’s clear that both incumbent and new players will seize the opportunities that new technologies like AI and IoT bring, in order to deliver new financial services that go beyond traditional banking.</p>",
      image: "../../images/finance.png",
    },
    {
      title: "Public domain",
      quote:
        "Using technology to make government more accessible and proactive has the potential to prevent issues before they arise.",
      text:
        "<p>Over the last decade, much has been said about the negative effects of the digitalisation of society. Far less attention has been spent on the potential benefits digital can offer in terms of how the public domain functions and how governments interact with citizens. But administrations all around the world are stepping up their game, experimenting with new team structures and focused delivery services in order to make the experience of interacting with governments as pleasant, transparent and seamless as we have grown accustomed to with ‘normal’ companies.</p><p>For thoroughly legitimate reasons, governments, cities, municipalities and governmental organisations are bound to processes that make digital transformation particularly challenging. But still, initiatives like USDS (US), GDS (UK) and, of course, Estonia’s <a href='https://medium.com/@geoffrooy/e-estonia-the-ultimate-digital-democracy-f67bc21a6114' target='_blank'>groundbreaking efforts</a> in the digitising of citizenship, pave the way for even more public initiatives aiming to simplify and digitise public services. And the opportunities to create societal value are many and varied. Just consider making patient records directly available to both patients and health service providers in ways that are not only seamless, but also secure and trusted. Using technology to make government more accessible and proactive has the potential to prevent issues before they arise, creating more trust between citizens and their governments, and creating substantial value for the societies they service.</p>",
      image: "../../images/public.png",
    },
    {
      title: "Employee experience",
      quote:
        "Companies that invest in Employee Experience show 4x more profitability (<a href='https://hbr.org/2017/03/why-the-millions-we-spend-on-employee-engagement-buy-us-so-little' target='_blank'>source</a>).",
      text:
        "<p>As companies go through digital transformation, they will at some point realise that an important part of that process is all about what it means for the work force. Digitalisation brings with it serious changes, not just in the skills required from workers, but also in how we communicate, collaborate and learn. Overseeing the importance of the ‘in-house’ effects of digital transformation could prove to be a major hurdle, but at the same time, unlocking radical improvements in how employees work and collaborate offers major benefits.</p><p>Today, most companies have learned to focus on customer needs. Meanwhile however, they are failing miserably to meet the rising expectations of their employees, and consequently not doing themselves any favours. There is plenty of evidence to back up the notion that investing in how your workforce works (and the tools they use) delivers highly engaged employees and better results. So, creating new digital solutions that offer an improved employee experience just makes solid business sense.</p>",
      image: "../../images/employee.png",
    },
  ],
  footer: {
    title: "In conclusion:",
    text:
      "So much for predicting the future. No matter how the next decade plays out, it’s quite safe to say that the need for larger companies - and the sectors in which they operate - to continue to evolve will certainly be no less than what we have seen in the last two decades. So whatever happens, it will be anything but boring. In the meantime: once again all our best wishes. May the 2020s bring you 10 healthy, beautiful and successful years.",
  },
}
