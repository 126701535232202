import React from "react"
import styled, { css } from "styled-components"

// Styling
import textStyles from "../styles/textStyles"
import { mq } from "../styles/breakpoints"

// Components
import { Row, Col } from "../components/layout/Grid"

interface IProps {
  title?: string
  text: string
  quote?: string
  textColor: string
}

const SliderCopy = ({ title, text, textColor, quote }: IProps) => (
  <StyledRow textColor={textColor} marginTop={title === undefined}>
    {title && (
      <Col
        xxsOffset={1}
        xxs={6}
        mOffset={1}
        m={14}
        lOffset={2}
        l={12}
        xxlOffset={2}
        xxl={8}
      >
        <Title>{title}</Title>
      </Col>
    )}
    <Col
      xxsOffset={1}
      xxs={6}
      mOffset={1}
      m={13}
      lOffset={2}
      l={13}
      xxlOffset={2}
      xxl={6}
    >
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </Col>
    {quote && (
      <Col
        xxsOffset={1}
        xxs={6}
        mOffset={1}
        m={13}
        lOffset={2}
        l={13}
        xxlOffset={1}
        xxl={5}
      >
        <Quote>
          <div dangerouslySetInnerHTML={{ __html: `“${quote}”` }}></div>
        </Quote>
      </Col>
    )}
  </StyledRow>
)

interface IStyledRow {
  textColor: string
  marginTop?: boolean
}
const StyledRow = styled(Row)<IStyledRow>`
  color: ${({ textColor }) => textColor && textColor};
  margin: ${({ marginTop }) => (marginTop ? "120px 0 0" : "0")};

  ${({ marginTop }) => css`
    ${mq.to.M`
      margin: ${marginTop ? "80px 0 0" : "0"};
    `}
  `}
`

const Title = styled.h2`
  ${textStyles.pageTitle};
`

const Quote = styled.blockquote`
  ${textStyles.quote};
  text-align: center;
`

export default SliderCopy
