import React from "react"
import styled from "styled-components"

// Content
import content from "../content/content"

// Components
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Intro from "../components/Intro"
import Footer from "../components/Footer"
import Highlight from "../components/Highlight"
import IndustryBlock from "../components/IndustryBlock"
import { Row, Col } from "../components/layout/Grid"

// Styling
import textStyles from "../styles/textStyles"
import { mq } from "../styles/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO title="Happy New Decade!" />
    <Intro />
    <Highlights>
      {content.highlights.map((highlight, i) => (
        <Highlight
          key={`Highlight-${highlight.title}-${i}`}
          pattern={highlight.pattern as "pattern1" | "pattern2" | "pattern3"}
          backgroundColor={
            highlight.backgroundColor as "black" | "white" | "red"
          }
          previousColor={
            highlight.previousBackgroundColor as "black" | "white" | "red"
          }
          footer={highlight.footer}
          title={highlight.title}
          text={highlight.text}
          quote={highlight.quote}
          secondQuote={highlight.secondQuote}
          thirdQuote={highlight.thirdQuote}
          secondText={highlight.secondText}
          thirdText={highlight.thirdText}
          smallImage={highlight.smallImage}
          largeImage={highlight.largeImage}
          secondSmallImage={highlight.secondSmallImage}
          secondLargeImage={highlight.secondLargeImage}
        />
      ))}
    </Highlights>
    <Row>
      <Col xxsOffset={1} xxs={6} mOffset={1} m={14} lOffset={2} l={10}>
        <PageTitle>{content.industriesTitle}</PageTitle>
      </Col>
    </Row>
    {content.industries.map((industry, i) => (
      <IndustryBlock
        key={`Industry-${industry.title}-${i}`}
        title={industry.title}
        text={industry.text}
        quote={industry.quote}
        image={industry.image}
        layout={i % 2 === 0 ? "textRight" : "textLeft"}
        nr={i + 1}
      />
    ))}
    <Footer />
  </Layout>
)

const Highlights = styled.div`
  margin-bottom: 120px;

  ${mq.from.M`
    margin-bottom: 240px;
  `}
`

const PageTitle = styled.h1`
  ${textStyles.pageTitle};
`

export default IndexPage
