import React from "react"
import styled from "styled-components"

// Assets
import image from "../assets/arrows.gif"
import pdf from "../assets/2019-Jungle-Minds-Happy-new-decade.pdf"

// Content
import content from "../content/content"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { mq } from "../styles/breakpoints"

// Components
import { Row, Col } from "./layout/Grid"

const Intro = () => (
  <Container>
    <Row>
      <Col xxsOffset={1} xxs={6} mOffset={1} m={14} lOffset={2} l={10}>
        <Title>{content.intro.title}</Title>
      </Col>
    </Row>
    <Row>
      <Col xxsOffset={1} xxs={6} mOffset={1} m={9} lOffset={2} l={6}>
        <div dangerouslySetInnerHTML={{ __html: content.intro.text }}></div>
      </Col>
      <Col xxsOffset={1} xxs={6} mOffset={1} m={4} lOffset={2} l={4}>
        <Image src={image} alt={content.intro.download} />
        <Link href={pdf} download>
          {content.intro.download}
        </Link>
      </Col>
    </Row>
  </Container>
)

const Container = styled.div`
  margin-bottom: 120px;
  ${mq.from.L`
    margin-bottom: 240px;
  `}
`

const Title = styled.h1`
  ${textStyles.pageTitle};
`

const Link = styled.a`
  ${textStyles.title};
  display: block;
  color: ${colors.black};
  text-decoration: none;
  text-align: center;
  line-height: 1;
  position: relative;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${colors.accent1};
  }
`

const Image = styled.img`
  width: 100%;
  margin-bottom: 24px;

  ${mq.to.M`
    margin-top: 32px;
  `}

  ${mq.from.L`
    margin-bottom: 40px;
  `}
`

export default Intro
