import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { mq } from "../styles/breakpoints"

// Components
import { Row, Col } from "./layout/Grid"

interface IProps {
  title: string
  text: string
  quote: string
  image: string
  layout?: "textLeft" | "textRight"
  nr: number
}

const IndustryBlock = ({
  title,
  text,
  quote,
  image,
  layout = "textRight",
  nr,
}: IProps) => (
  <Container>
    <Row>
      <Col xxsOffset={1} xxs={7} mOffset={1} m={15}>
        <Title>
          — 0{nr}
          <br />
          {title}
        </Title>
      </Col>
    </Row>
    <StyledRow reverse={layout === "textLeft"}>
      {layout === "textRight" ? (
        <>
          <Col xxsOffset={1} xxs={6} mOffset={1} m={6} lOffset={2} l={6}>
            <Quote>
              <div dangerouslySetInnerHTML={{ __html: quote }}></div>
            </Quote>
            <Image src={image} alt={title} />
          </Col>
          <Col xxsOffset={1} xxs={6} mOffset={1} m={7} lOffset={1} l={5}>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </Col>
        </>
      ) : (
        <>
          <Col xxsOffset={1} xxs={6} mOffset={1} m={7} lOffset={2} l={5}>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </Col>
          <Col xxsOffset={1} xxs={6} mOffset={1} m={6} lOffset={1} l={6}>
            <Quote>
              <div dangerouslySetInnerHTML={{ __html: quote }}></div>
            </Quote>
            <Image src={image} alt={title} />
          </Col>
        </>
      )}
    </StyledRow>
  </Container>
)

const Container = styled.div`
  margin-top: 64px;
  margin-bottom: 120px;

  ${mq.from.M`
    margin-bottom: 240px;
  `}
`

interface IStyledRow {
  reverse: boolean
}
const StyledRow = styled(Row)<IStyledRow>`
  ${mq.to.M`
    ${({ reverse }: { reverse: boolean }) =>
      reverse &&
      `
        flex-direction: column-reverse;
      `}
  `}

  a {
    color: ${colors.black};
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${colors.accent1};
    }
  }
`

const Title = styled.h3`
  ${textStyles.title};
  margin-bottom: 60px;
  border-top: 2px solid ${colors.black};
  padding-top: 16px;

  ${mq.from.M`
    margin-bottom: 120px;
  `}

  ${mq.from.L`
    margin-bottom: 180px;
  `}
`

const Image = styled.img`
  width: 100%;
  margin-bottom: 32px;

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const Quote = styled.blockquote`
  ${textStyles.quote};
`

export default IndustryBlock
