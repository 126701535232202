import React from "react"
import styled, { css } from "styled-components"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/breakpoints"

// Components
import SliderCopy from "./SliderCopy"
import Pattern1 from "./patterns/Pattern1"
import Pattern2 from "./patterns/Pattern2"
import Pattern3 from "./patterns/Pattern3"
import PatternFooter from "./patterns/PatternFooter"

type IColors = "white" | "red" | "black"

interface IImage {
  src: string
  ratio: number
}

interface IProps {
  pattern: "pattern1" | "pattern2" | "pattern3"
  backgroundColor: IColors
  previousColor: IColors
  footer: boolean
  title: string
  text: string
  quote?: string
  secondText?: string
  secondQuote?: string
  thirdQuote?: string
  thirdText?: string
  smallImage: IImage
  largeImage: IImage
  secondSmallImage?: IImage
  secondLargeImage?: IImage
}

const Highlight = ({
  pattern,
  backgroundColor,
  previousColor,
  footer,
  title,
  text,
  quote,
  secondText,
  secondQuote,
  thirdText,
  thirdQuote,
  smallImage,
  largeImage,
  secondSmallImage,
  secondLargeImage,
}: IProps) => {
  const colorBackground =
    backgroundColor === "white"
      ? colors.white
      : backgroundColor === "red"
      ? colors.accent1
      : colors.black
  const colorPrevious =
    previousColor === "white"
      ? colors.white
      : previousColor === "red"
      ? colors.accent1
      : colors.black
  const textColor = backgroundColor === "black" ? colors.white : colors.black

  const BlockPattern =
    pattern === "pattern1" ? (
      <Pattern1
        color={colorBackground}
        previousColor={colorPrevious as IColors}
      />
    ) : pattern === "pattern2" ? (
      <Pattern2
        color={colorBackground}
        previousColor={colorPrevious as IColors}
      />
    ) : (
      pattern === "pattern3" && (
        <Pattern3
          color={colorBackground}
          previousColor={colorPrevious as IColors}
        />
      )
    )

  return (
    <>
      {BlockPattern}
      <ContentWrapper backgroundColor={colorBackground}>
        <SliderCopy
          title={title}
          text={text}
          textColor={textColor}
          quote={quote}
        />
        <ImageContainer>
          <BackgroundImage
            srcSmall={smallImage.src}
            srcLarge={largeImage.src}
            ratioSmall={smallImage.ratio}
            ratioLarge={largeImage.ratio}
            role="img"
            aria-label="highlight image"
          />
        </ImageContainer>
        {secondText && (
          <SliderCopy
            text={secondText}
            textColor={textColor}
            quote={secondQuote}
          />
        )}
        {secondSmallImage && secondLargeImage && (
          <ImageContainer>
            <BackgroundImage
              srcSmall={secondSmallImage.src}
              srcLarge={secondLargeImage.src}
              ratioSmall={secondSmallImage.ratio}
              ratioLarge={secondLargeImage.ratio}
              role="img"
              aria-label="highlight image"
            />
          </ImageContainer>
        )}
        {thirdText && (
          <SliderCopy
            text={thirdText}
            textColor={textColor}
            quote={thirdQuote}
          />
        )}
      </ContentWrapper>
      {footer && (
        <PatternFooter
          color={colorBackground}
          previousColor={colorPrevious as IColors}
        />
      )}
    </>
  )
}

interface IContentWrapper {
  backgroundColor: string
}
const ContentWrapper = styled.div<IContentWrapper>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 120px 0;

  ${mq.from.M`
    padding: 240px 0;
  `}
`

const ImageContainer = styled.div`
  padding: 0 20px;
  margin-top: 120px;

  ${mq.to.M`
    max-width: 350px;
    margin: 80px auto 0;
  `}
`
interface IBackgroundImage {
  srcSmall: string
  srcLarge: string
  ratioSmall: number
  ratioLarge: number
}
const BackgroundImage = styled.div<IBackgroundImage>`
  ${props => css`
    background-size: cover;
    background-position: center;
    background-image: url(${props.srcSmall});

    ${mq.from.M`
      background-image: url(${props.srcLarge});
    `}
  `}

  ${props => css`
    padding-top: ${props.ratioSmall}%;

    ${mq.from.M`
      padding-top: ${props.ratioLarge}%;
    `}
  `}
`

export default Highlight
