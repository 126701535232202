import React from "react"
import styled from "styled-components"

type IBorderRadius = "topLeft" | "topRight" | "bottomRight" | "bottomLeft"

interface IProps {
  borderRadius?: IBorderRadius
  color: string
}

const Block = ({ borderRadius, color }: IProps) => (
  <Container borderRadius={borderRadius} color={color} />
)

interface IContainer {
  borderRadius?: IBorderRadius
}
const Container = styled.div<IContainer>`
  width: 100%;
  padding-top: 100%;
  background-color: ${({ color }) => color};

  ${({ borderRadius }) =>
    borderRadius === "topLeft"
      ? `border-top-left-radius: 100%`
      : borderRadius === "topRight"
      ? `border-top-right-radius: 100%`
      : borderRadius === "bottomRight"
      ? `border-bottom-right-radius: 100%`
      : borderRadius === "bottomLeft" && `border-bottom-left-radius: 100%`}
`

export default Block
