import React from "react"
import styled from "styled-components"

// Components
import Logo from "../assets/Logo.svg"
import { Row, Col } from "../components/layout/Grid"

// Content
import content from "../content/content"

// Styles
import textStyles from "../styles/textStyles"
import { mq } from "../styles/breakpoints"

const Footer = () => (
  <Container>
    <Row>
      <Col xxsOffset={1} xxs={6} mOffset={1} m={14} lOffset={2} l={10}>
        <Title>{content.footer.title}</Title>
        {content.footer.text}
      </Col>
    </Row>
    <Image src={Logo} alt="JM-logo" />
  </Container>
)

export default Footer

const Title = styled.h3`
  ${textStyles.pageTitle};
`

const Container = styled.div`
  padding-bottom: 120px;
`

const Image = styled.img`
  height: 80px;
  display: flex;
  margin: 120px auto 0;
  padding: 0 12.5%;

  ${mq.from.M`
    margin: 240px auto 0;
    padding: 0;
  `}
`
