import React from "react"
import styled from "styled-components"

// Components
import { Row, Col } from "../layout/Grid"
import Block from "./Block"

type IPreviousColor = "black" | "white" | "red"

interface IProps {
  color: string
  previousColor: IPreviousColor
}

const Pattern2 = ({ color, previousColor }: IProps) => (
  <Container backgroundColor={previousColor}>
    <Row>
      <Col xxsOffset={1} xxs={1} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxsOffset={3} xxs={1} mOffset={3} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={3} m={1}>
        <Block color={color} borderRadius="topRight" />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
    </Row>
    <Row>
      <Col xxs={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={1} m={1}>
        <Block color={color} borderRadius="bottomRight" />
      </Col>
      <Col xxsOffset={2} xxs={1} mOffset={2} m={1}>
        <Block color={color} borderRadius="topLeft" />
      </Col>
      <Col xxsOffset={1} xxs={1} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={2} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} m={1}>
        <Block color={color} />
      </Col>
    </Row>
    <Row>
      <Col xxs={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxsOffset={2} xxs={1} mOffset={2} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxsOffset={1} xxs={1} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} m={1}>
        <Block color={color} />
      </Col>
      <Col xxs={0} mOffset={1} m={1}>
        <Block color={color} />
      </Col>
    </Row>
  </Container>
)

const Container = styled.div`
  ${({ backgroundColor }: { backgroundColor: IPreviousColor }) =>
    `background-color: ${backgroundColor}`};
`

export default Pattern2
